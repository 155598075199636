/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~ionic4-auto-complete/auto-complete";

$font-path: "../assets/fonts";

@import url('https://fonts.googleapis.com/css?family=Muli:300,400,600,700');

$font-heading: 'Muli', sans-serif;
$font-body: 'Roboto', sans-serif;

.native-textarea[disabled].sc-ion-textarea-md {
    opacity: 1 !important;
    // height: auto !important;
}

ion-title {
    font-size: 22px;
    font-weight: 500;
    font-family: $font-heading;
    padding: 0px 10px;
}

ion-menu-button {
    color: #222;
}

html{
    font-family: "Roboto", "Helvetica Neue", sans-serif;
}

@function md-type-size($value) {
    @return ($value / 16)+em;
}

@function md-type-tracking($value, $font-size) {
    @return ($value / ($font-size / 16))+px;
}

// @each $size, $size-value in $screen-breakpoints {
//   .hide-#{$size} {
//     @media (min-width: $size-value) {
//       display: none !important;
//     }
//   }
// }

.error-message {
    color: var(--ion-color-swdanger);
}

ion-auto-complete {
    width: 100%;
    border: 1px solid #919191;
    border-radius: 11px;
    ul {
        width: 90%;
    }
}

.toastAlert {
    --width: 120px;
    --height: 120px;
    // --start: 40%;
    opacity: 0.8;
    --background: #222;
    --color: #79b540;
    font-size: 22px;
    display: block;
    text-align: center;
}
 
.activity-detail-loading {
	ion-backdrop{
		opacity: 1 !important;	
	}
    --color: #00b395;
    --background: transparent;
    box-shadow: none;
}

.searchbar-input.sc-ion-searchbar-md {
    padding-left: unset;
    padding-right: unset;
    padding-inline-start: 10px;
    padding-inline-end: 25px;
    background-position: left 8px center;
    height: auto;
    font-size: 13px;
    line-height: 22px;
    width: 100%;
    // border: 1px solid #222;
    // box-shadow: 0px 0px 2px;
}

.sc-ion-searchbar-md-h {
    box-shadow: none;
}

ion-auto-complete .searchbar-clear-button.sc-ion-searchbar-md {
    right: 10px;
}

.searchbar-input-container .searchbar-search-icon {
    display: none !important;   
}

.header-translucent-ios ion-toolbar {
    --opacity: .95;
}


.md-heading-1 {
    font-family: var(--ion-font-head);
    font-weight: 300;
    font-size: md-type-size(96);
    letter-spacing: md-type-tracking(-1.5, 96);
}

.md-heading-2 {
    font-family: var(--ion-font-head);
    font-weight: 300;
    font-size: md-type-size(60);
    letter-spacing: md-type-tracking(-0.5, 60);
}

.md-heading-3 {
    font-family: var(--ion-font-head);
    font-weight: 400;
    font-size: md-type-size(48);
}

.md-heading-4 {
    font-family: var(--ion-font-head);
    font-weight: 400;
    font-size: md-type-size(34);
    letter-spacing: md-type-tracking(0.25, 34);
}

.md-heading-5 {
    font-family: var(--ion-font-head);
    font-weight: 400;
    font-size: md-type-size(17);
}

.md-heading-6 {
    font-family: var(--ion-font-head);
    font-weight: 600;
    font-size: md-type-size(20);
    letter-spacing: md-type-tracking(0.15, 20);
}

.md-subtitle-1 {
    font-family: var(--ion-font-head);
    font-weight: 400;
    font-size: md-type-size(16);
    letter-spacing: md-type-tracking(0.15, 16);
}

.md-subtitle-2 {
    font-family: var(--ion-font-head);
    font-weight: 600;
    font-size: md-type-size(14);
    letter-spacing: md-type-tracking(0.1, 14);
}

.md-body-1 {
    font-family: var(--ion-font-body);
    font-weight: 400;
    font-size: md-type-size(18);
    letter-spacing: md-type-tracking(0.5, 16);
}

.md-body-2 {
    font-family: var(--ion-font-body);
    font-weight: 400;
    font-size: md-type-size(16);
    letter-spacing: md-type-tracking(0.25, 14);
}

.md-button {
    font-family: var(--ion-font-body);
    font-weight: 600;
    font-size: md-type-size(14);
    letter-spacing: md-type-tracking(0.75, 14);
    text-transform: uppercase;
}

.md-caption {
    font-family: var(--ion-font-body);
    font-weight: 400;
    font-size: md-type-size(12);
    letter-spacing: md-type-tracking(0.4, 12);
}

.md-overline {
    font-family: var(--ion-font-body);
    font-weight: 400;
    font-size: md-type-size(10);
    letter-spacing: md-type-tracking(1.5, 10);
    text-transform: uppercase;
}

.text-primary {
    color: var(--ion-color-swprimary);
}
.text-danger {
    color: var(--ion-color-swsecondary);
}
.text-secondary {
    color: var(--ion-color-swdanger);
}
.static {
    position: static;
}

.vchatpopover{
    .popover-content
    {
        width: 190px;
        height: 30px;
        // margin-left:-31px;
        margin-top: 10px;
        overflow: visible;
        border-radius: 5px;
    }    
}

.consultpopover {
    .popover-content
    {
        width: 160px;
        min-height: 30px;
        margin-right:-200px;
        margin-top: -235px;
        overflow: visible;
        border-radius: 5px;
    }    
}

.triageDocListPopover{
    .popover-content {
        min-width: 250px;
        max-height: 63%;
        border-radius: 15px;
        overflow:auto;
    }
    
    ::-webkit-scrollbar {
        display: none;
    }

}

.timeSchedulePopover{
    .popover-content {
        min-width: 300px;
        height: 480px;
        border-radius: 15px;
        overflow: hidden;
    }
}

.triageDocListPopover{
    .popover-content {
        min-width: 250px;
        max-height: 63%;
        border-radius: 15px;
        overflow-y: scroll;
    }
    
    ::-webkit-scrollbar {
        display: none;
    }

}

.fileUploadPopover {
    .popover-content
    {
        width: 250px;
        height: 40px;
        /* margin-bottom: 10px; */
        overflow: visible;
        border-radius: 5px;
        margin-top: -20px;
        right: 10px;
    }    
}

.getpatientpopover{
    .popover-content
    {
        width: 280px;
        min-height: 150px;
        // margin-left:-31px;
        overflow: visible;
        border-radius: 5px;
        padding: 10px;
    }    
}

.apptinfo{
    .popover-content
    {
        width: 230px;
        height: auto;
        // margin-left:-31px;
        overflow: visible;
        border-radius: 5px;
        padding: 10px;
        --background: #222 !important;
        opacity: 0.85;
    }    
}

.alert-tappable.sc-ion-alert-md {
    position: relative;
    height: 38px;
    /* overflow: visible; */
}

.alert-head.sc-ion-alert-md {
    text-align: start;
    padding: 12px;
}

.alert-radio-group.sc-ion-alert-md, .alert-checkbox-group.sc-ion-alert-md {
    position: relative;
    min-height: 270px;
    border-top: 1px solid var(--ion-color-step-150, #d9d9d9);
    border-bottom: 1px solid var(--ion-color-step-150, #d9d9d9);
}

.alert-button-group.sc-ion-alert-md {
    padding: 3px;
}

.c-form {
    &__label {
        margin-bottom: 5px;
        font-family: var(--ion-font-body);
        color: #222;
        font-size: 15px;
        margin-top: 9px;
        sup{
            color: var(--ion-color-danger);
            font-size: 16px;
            top: -0.05em;
        }
        &-2{
            width: 50%;
        }
        &-3{
            width: 38%;
        }
        &-4{
            width: 100%;
        }
        &-5 {
            width: 30%;
            color: #222;
        }
    }
    &__radio {
        vertical-align: middle;
        margin-right: 4px;
    }
    &__field{
        position: relative;
        width: 100%;
        margin-bottom: 5px;
        padding-right: 5px;
        padding-top: 5px;
    }
    &__fieldhalf{
        position: relative;
        width: 50%;
        margin-bottom: 5px;
        padding-right: 5px;
        padding-top: 5px;
    }
    &__input {
        font-size: 15px;
        padding: 10px;
        display: block;
        width: 100%;
        border-radius: 4px;
        background-color: #F0F2F5;
        color: #222;
        font-family: var(--ion-font-body); 
        border: 1px solid #e4e4e4;
        min-height: 40px;
        margin-bottom: 5px;
        text-transform: initial;
    }
    &__select {
        font-size: 15px;
        padding: 10px;
        padding-right: 30px;
        display: block;
        width: 100%;
        border-radius: 4px;
        font-family: var(--ion-font-body);
        background-color: #F0F2F5;
        color: #222;
        border: 1px solid #e4e4e4;
        /* appearance: none;
        -webkit-appearance: none; */
        outline: 0!important;
    }
    &__select1 {
        font-size: 15px;
        padding: 10px;
        // padding-right: 30px;
        width: 100%;
        border-radius: 4px;
        font-family: var(--ion-font-body);
        background-color: #F0F2F5;
        color: #222;
        border: 1px solid #e4e4e4;
        /* appearance: none;
        -webkit-appearance: none; */
        outline: 0!important;
    }
    &__date {
        font-size: 15px;
        color: #222;
        font-family: var(--ion-font-body);
        border: none;
        width: 100%;
        padding: 10px;
        --background: var(--ion-color-primary-bg);
        -webkit-appearance: none;
        appearance: none;
        min-height: 40px;
    }    
}

.c-accordion {
    &__item {
    }
    &__link {
        position: relative;
        padding: 15px;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        margin: 0!important;
        cursor: pointer;    
        background: #e6f2ff; 
        @extend .md-heading-5;   
        color: #222;
    }
    &__content {
        padding: 30px;
        &.is-active {
            display: block;
        }
    }
    &__content1 {
        padding: 0px;
        &.is-active {
            display: block;
        }
    }
    &__link-icon {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
    }
}

.title {
    @extend .md-heading-5;
}

.c-subtitle {
    margin-top: 0;
    margin-bottom: 10px;
    font-family: var(--ion-font-head);
    font-size: 16px;
    color: #222;
}

.c-card {
    margin-bottom: 20px;
    padding: 15px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    > *:last-child {
        margin-bottom: 0;
    }
}

.c-list {
    margin-bottom: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    // --background: var(--ion-color-primary-bg);
    &__title {
        font-size: 16px;
        font-weight: 400;
        font-family: var(--ion-font-head);
        color: #222;
    }
    &__title-item {
        padding: 10px;
        h3 {
            margin: 0;
            color: var(--ion-color-primary);
        }
        &--primary {
            background-color: var(--ion-color-swprimarylight);
        }
        &--secondary {
            background-color: var(--ion-color-swsecondarylight);
        }
        &--danger {
            background-color: var(--ion-color-swdangerlight);
        }
    }
    &__title-icon {
        margin-right: 10px;
        font-size: 18px;
    }
    &__item {
        position: relative;
        padding: 10px;
        width: 100%;
        border-top: 1px solid rgba(0, 0, 0, 0.08);
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        outline: none;
        text-decoration: none;
    }
    &__label {
        font-size: 15px;
        color: #222222;
        font-family: var(--ion-font-body);
    }
    &__input {
        font-size: 15px;
        color: #919191;
        font-family: var(--ion-font-body);
        border: none;
        width: 100%;
        padding-left: 5px;
        margin-top: 6px;
        // --background: var(--ion-color-primary-bg);
    }
}
.c-button {
    display: block;
    // --background: var(--ion-color-primary-bg);
    color: #222;
    text-align: center;
    padding: 10px;
    appearance: none;
    -webkit-appearance: none;
    font-size: 15px;
    border-radius: 4px;
    min-height: 40px;
    font-family: var(--ion-font-body);
    &--fullwidth {
        width: 100%;
    }
    &--primary {
        color: #fff;
        background: #0059b3;
    }
    &--light {
        color: #0059b3;
        background: #e6f2ff;
    }
    &--disabled {
        color: #fff;
        background: #0059b3;
        opacity: 0.5;
        cursor: not-allowed !important;
    }
}

::-webkit-scrollbar {
    width:0px;
    display: none;
}

.hosplist {
    .popover-content
    {
        width: 350px;
        height: auto;
        border-radius: 10px;
    } 
}

.btn{
    border-radius: 4px;    
    --transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), background-color 15ms linear, color 15ms linear;    
    margin-bottom: 10px;
    height: 36px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    font-family: var(--ion-font-body);
    letter-spacing: 0.06em;
    text-transform: uppercase;    
    --background-hover: #ffffff;
    --background-focused: #ffffff;
    --background-activated-opacity: 0;
    --background-focused-opacity: 0.24;
    --background-hover-opacity: 0.08;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
 .btn-full {
     width: 100%;
 }

 .btn-primary {
    background-color: #045169;
 }
 .btn-bluedark {
    background-color: #045169;
 }
 .btn-danger {
    background-color: var(--ion-color-danger);
 }

 .btn-swprimary {
    background-color: var(--ion-color-swprimary);
 }

 .tablets2{
    margin: 20px;
    display: flex;
    img{
        width:30px;
        height: auto;
        margin-right:5px;
        display:inline;
    }
}
.tablets3{
    margin: 20px;
    display: flex;
    img{
        width:30px;
        height: auto;
        margin-right:5px;
        display:inline;
    }
}


.share_circle {
    .popover-content{
        top: 10% !important;
    }
}

.appointment-modal {
    --border-radius: 4px;
    --width: 90%;
    --height: 90%;
}

.slot-modal {
    --border-radius: 4px;
    --width: 40%;
    --height: 70%;
}

.add-batch-modal { 
    --border-radius: 4px;
    --width: 60%;
    --height: 70%;
}

.fullscreen {
    --width:100% !important;
    --height:100% !important;
}

.loader
    {
        background-color: #919191;
        --width:100%;
        --height:100%;
    }

.sendNotification
{
    top: 0 !important;
    left: 350px !important;
}

.patientAddress {
    --border-radius: 4px;
    --width: 30%;
    --height: 25%;
}

.partialpaiddue-modal {
    --border-radius: 4px;
    --width: 30%;
    --height: 50%;
}

.billEdit-modal {
    --border-radius: 4px;
    --width: 80%;
}

@media (max-width: 480px) {

    .tablets2{
        margin: 0 auto;
        img{
            width:30px;
            height: auto;
            margin-right:5px;
            display:inline;
        }
    }
    .tablets3{
        margin: 0 auto;
        img{
            width:25px;
            height: auto;
            margin-right:5px;
            display:inline;
        }
    }

    ion-calendar [col-auto]:nth-child(2){
        width:100%;
    }

    .slot-modal {
        --border-radius: 4px;
        --width: 60%;
        --height: 70%;
    }
    
}